import React from "react";
// Components
import Wrapper from "../components/Wrapper";
import Seo from "../components/Seo";
import Contents from "../components/Contents";

const steps = [
    'StepWho',
    'StepAvailability',
    'StepMail',
    'StepName',
    'StepNumber',
    'StepDone',
]

const Version1 = () => {
    return (
        <Wrapper> <Seo title="Elektromobilvergleich" description="Vergleichen und bis zu 3.600€ sparen!"/>
            <Contents heading={'Ihre gratis Probefahrt in nur wenigen Klicks!'} subheading={'Jetzt ausfüllen und bis zu 3600€ sparen.'} steps={steps} version={'v1b'} />
        </Wrapper>
    )
}

export default Version1
